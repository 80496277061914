$body-bg-dark: #1d1d26;

@import "../node_modules/bootstrap/scss/bootstrap";

.container-fluid{
    padding-right: 3.75rem;
    padding-left: 3.75rem;
}

.w100 {
  width: 100%;
}