.category-header {
    font-size: 1.5rem;
    line-height: 2rem;
    color: white;
    display: flex;
    align-items: center;
}
.game-card {
    margin-bottom: 2rem;
    .title {
        font-size: 1rem;
        line-height: 1.5rem;
        color: white;
        margin-top: 6px;
    }
    .thumbnail-holder {
        position: relative;
        border-radius: 0.375rem;
        overflow: hidden;
        .gradient {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg,rgba(58,58,80,0) 50%,#2a2a3e);
        }
        canvas {
            position: absolute;
            width: 80%;
        }
        .history {
            position: absolute;
            bottom: 0;
            display: flex;
            width: 100%;
            padding: 0.5rem;
            justify-content: space-between;
            .history-item {
                box-sizing: border-box;
                display: flex;
                height: 22px;
                justify-content: center;
                position: relative;
                width: 22px;
            }
        }
    }
    .country-flag {
        border-radius: 0.25rem;
        margin-left: 0.5rem;
    }
    .price {
        color: #fc0;
    }
    .users {
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
    }
}
