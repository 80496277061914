.search-control {
    position: relative;
    .search-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
    }
    input.form-control {
        padding-left: 42px;
    }
}

.navbar-main {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    .nav-link {
        padding: 0.75rem 1.5rem !important;
        display: flex;
        align-items: center;
        max-height: 2.5rem;
        &.active {
            background-color: #383842;
        }
    }
    .header-controls{
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }
    .navbar-button {
        border-radius: 1.5rem;
        &:hover, &.active {
            background: #ffffff1a;
        }
    }
}